<template>
  <div class="mobile-user-guidance" :class="{'hide' : !showGuide}" @click="showGuide = false">
    <div class="text-center">
      <img src="@/assets/img/swipe.png" class="invert mb-3" style="width: 27vw;"/>
      <p class="m-0 bold text-white" style="letter-spacing: .8px;">Swipe Left/Right<br/>to Navigate Tables</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "MobileGuide",
  data() {
    return {
      showGuide: false
    }
  },
  mounted() {
    this.mobileGuide()
  },
  methods: {
    mobileGuide() {
      const android = /Android/.test(navigator.userAgent);
      const ios = /(iPhone|iPad|iPod)/.test(navigator.platform);
      if (android || ios) this.showGuide = true
    }
  }
}
</script>
<style scoped>
.mobile-user-guidance {
  position: fixed;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, .6);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: .5s all;
  opacity: 1;
  visibility: visible;
}
.mobile-user-guidance.hide {
  opacity: 0;
  visibility: hidden;
}
.mobile-user-guidance div {
  position: absolute;
  bottom: 10rem;
}
</style>
